<template>
  <div v-if='detailsItemId && !isLoading'
    class='p-4 lg:p-8'>
    <iframe v-if='sessionDetailUrl' 
      :src='sessionDetailUrl' 
      width='100%'
      :height='iframeHeight' 
      class='border-2'>
    </iframe>
    <component v-else
      :is='contentComponent'
      :session='session'
      :in-modal='true'
      @go-to-current-session='goToSession' />
    <div v-if='showGoToSessionButton'
      class='flex flex-row justify-end my-4 mr-2 pb-2'>
      <button 
        class='rounded-md py-4 px-12 uppercase text-white font-semibold' 
        :style='eventMainThemeColorBg'
        @click='goToSession'>
        {{ goToSessionButtonText }}
      </button>
    </div>
  </div>
  <div v-else-if='isLoading'
    class='p-8'>
    <div class='text-center py-12 mx-auto w-full max-w-lg px-8 flex flex-row justify-center items-center gap-x-2'>
      Loading... <loading-spinner class='' />
    </div>
  </div>
  <div v-else
    class='p-8'>
    <div class='bg-gray-50 rounded-md text-center py-12 mx-auto w-full max-w-lg px-8'>
      There was an error loading the details for this session. <br>
      Please try again later.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'ProgramSessionDetails',
  components: {
    LoadingSpinner,
    'session-details-full': () => import('@/components/sessions/SessionDetailsFull.vue'),
    'session-details-full-khrs-program': () => import('@/components/khrs-virtual/SessionDetailsFullKhrsProgram.vue'),
    'session-details-full-icola': () => import('@/components/icola2023/SessionDetailsFull.vue'),
  },
  props: {
    detailsItemId: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isLoading: false,
      session: {},
    }
  },
  watch: {
    detailsItemId: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.isLoading = true
          this.getSingleSessionDetails(newVal).then(resp => {
            this.session = resp
            this.isLoading = false
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
      'eventConfigSessionOfflineMessage',
      'eventMainThemeColorBg',
    ]),
    dialogFullscreenStatus () {
      return window.innerWidth < 1024
    },
    dialogTopMargin () {
      return this.dialogFullscreenStatus ? '' : '5vh'
    },
    dialogWidth () {
      return this.dialogFullscreenStatus ? '' : '90%'
    },
    contentComponent () {
      if (this.showingEventId === 11) {
        return 'session-details-full-khrs-program'
      } if (this.showingEventId === 32) {
        return 'session-details-full-icola'
      } else {
        return 'session-details-full'
      }
    },
    iframeHeight () {
      return this.dialogFullscreenStatus ? `${this.windowHeight - 140}px` : `${this.windowHeight*0.7}px`
    },
    showGoToSessionButton () {
      return this.session.sessionType !== 'not_started' &&
             this.session.sessionType !== 'offline' &&
             this.showingEventId !== 11 // dont show buttons at the bottom
    },
    goToSessionButtonText () {
      return this.showingEventId === 39 ? 'Sessions On Demand' : 'go to session'
    },
    sessionDetailUrl () {
      return (this.session.configurations && this.session.configurations.sessionDetailUrl) ? this.session.configurations.sessionDetailUrl : ''
    },
    blockViewingBeforeStartTime () {
      return this.session.configurations && this.session.configurations.blockViewingBeforeStartTime
    },
    blockViewingBeforeStartTimeMessage () {
      return this.session.configurations && this.session.configurations.blockViewingBeforeStartTimeMessage
    },
    blockViewingBeforeStartTimeButtonText () {
      return this.session.configurations && this.session.configurations.blockViewingBeforeStartTimeButtonText
    },
    isSessionTypeLive () {
      return this.session.sessionType === 'live_stream'
    },
    isSessionTypeVod () {
      return this.session.sessionType === 'vod'
    },
    isSessionTypePoster () {
      return this.session.sessionType === 'poster'
    },
    isSessionTypeExternalLink () {
      return this.session.sessionType === 'external_link'
    },
    isSessionTypeOffline () {
      return this.session.sessionType === 'offline'
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSingleSessionDetails',
      'goToCurrentSession',
    ]),
    ...mapActions('userActions', [
      'createUserAction',
    ]),
    goToPageBySessionType () {
      this.$modal.hide('details-modal')
      let query = Object.assign({}, this.$route.query)
      if (this.isSessionTypeLive) {
        this.goToCurrentSession(this.session.id)
      } else if (this.isSessionTypeVod) {
        query.session_id = this.session.id
        this.$router.push({name: 'Session', query: query}).catch(() => {})
      } else if (this.isSessionTypePoster) {
        this.$router.push({name: 'Poster', query: query}).catch(() => {})
      } else if (this.isSessionTypeExternalLink) {
        this.createUserAction({
          userActionName: 'open_session_external_link',
          userActionData: {
            event_id: this.showingEventId,
            session_id: this.session.id,
            content_id: this.session.configurations.externalLinkUrl
          }
        })
        window.open(this.session.configurations.externalLinkUrl, '_blank')
      } else if (this.isSessionTypeOffline) {
        this.$alert(`${this.eventConfigSessionOfflineMessage}`, {
          confirmButtonText: 'OK',
          type: 'info'
        })
      }
    },
    goToSession () {
      if (this.blockViewingBeforeStartTime && new Date() < new Date(this.session.scheduledStartTime)) {
        this.$alert(`${this.blockViewingBeforeStartTimeMessage}`, {
          confirmButtonText: this.blockViewingBeforeStartTimeButtonText,
          type: 'info'
        })
      } else {
        this.goToPageBySessionType()
      }
    },
  },
}
</script>
